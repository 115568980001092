<template>
  <DoseCardBase ref="baseCard" :session-response="sessionResponse">
    <template #title>Bolus dose prescription</template>
    <template #tabOneText>Dose prescription</template>

    <v-form v-model="formValid" ref="bolusPrescriptionForm">
      <v-card-text>
        <v-row no-gutters>
          <v-col md="10" class="pt-8 pb-5 pl-6">
            <h6>Prescribe bolus doses</h6>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="10" class="pb-8 pl-6">
            <v-row>
              <v-col sm="3" class="px-2">
                <DoseInput
                  :value="prescription.bolus_breakfast.value"
                  @input="onInput($event, 'bolus_breakfast')"
                  @change="
                    onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_VALUE')
                  "
                  @toggled-active="onToggle($event, 'bolus_breakfast')"
                  :validation-rules="getValidationRules('bolus_breakfast')"
                  data-testid="input-bolus-breakfast"
                  :is-togglable="true"
                  :deactivated="!prescription.bolus_breakfast.enabled"
                  :dosing-display-unit="getDosingDisplayUnit('bolus_breakfast')"
                  :required-text="`${$t(
                    'time-point.bolus_breakfast'
                  )} is required`"
                >
                  {{ $t('time-point.bolus_breakfast') }}
                </DoseInput>
              </v-col>

              <v-col sm="3" class="px-2">
                <DoseInput
                  :value="prescription.bolus_lunch.value"
                  @input="onInput($event, 'bolus_lunch')"
                  @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_VALUE')"
                  @toggled-active="onToggle($event, 'bolus_lunch')"
                  :validation-rules="getValidationRules('bolus_lunch')"
                  data-testid="input-bolus-lunch"
                  :is-togglable="true"
                  :deactivated="!prescription.bolus_lunch.enabled"
                  :dosing-display-unit="getDosingDisplayUnit('bolus_lunch')"
                  :required-text="`${$t('time-point.bolus_lunch')} is required`"
                >
                  {{ $t('time-point.bolus_lunch') }}
                </DoseInput>
              </v-col>

              <v-col sm="3" class="px-2">
                <DoseInput
                  :value="prescription.bolus_dinner.value"
                  @input="onInput($event, 'bolus_dinner')"
                  @change="
                    onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_VALUE')
                  "
                  @toggled-active="onToggle($event, 'bolus_dinner')"
                  :validation-rules="getValidationRules('bolus_dinner')"
                  data-testid="input-bolus-dinner"
                  :is-togglable="true"
                  :deactivated="!prescription.bolus_dinner.enabled"
                  :dosing-display-unit="getDosingDisplayUnit('bolus_dinner')"
                  :required-text="`${$t(
                    'time-point.bolus_dinner'
                  )} is required`"
                >
                  {{ $t('time-point.bolus_dinner') }}
                </DoseInput>
              </v-col>

              <v-col sm="3" class="px-2">
                <DoseInput
                  :value="prescription.bolus_extra_prescription.value"
                  @input="onInput($event, 'bolus_extra_prescription')"
                  @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_EXTRA_VALUE')"
                  @toggled-active="onToggle($event, 'bolus_extra_prescription')"
                  :validation-rules="
                    getValidationRules('bolus_extra_prescription')
                  "
                  data-testid="input-bolus-extra"
                  :is-togglable="true"
                  :deactivated="!prescription.bolus_extra_prescription.enabled"
                  :dosing-display-unit="
                    getDosingDisplayUnit('bolus_extra_prescription')
                  "
                  :required-text="`${$t(
                    'time-point.bolus_extra_prescription'
                  )} is required`"
                >
                  {{ $t('time-point.bolus_extra_prescription') }}
                </DoseInput>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center justify-end mr-6">
            <v-input :error-messages="bolusCountError" hide-details />
            <div v-if="bolusCountError" class="error--text mr-6 font-size-14">
              {{ bolusCountError }}
            </div>

            <div>
              <v-btn
                data-testid="add-confirm-button"
                rounded
                @click.prevent="createPrescription(getDoses())"
                color="secondary"
                :disabled="!formValid"
                :loading="isLoading"
                type="submit"
                elevation="1"
              >
                Continue
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </DoseCardBase>
</template>

<script>
import addPrescriptionMixin from './addPrescriptionMixin'
import DoseCardBase from './_DoseCardBase'

import DoseInput from '@/components/patient/add-patient/initial-dose/_DoseInput'
import isNumeric from '@/utils/isNumeric'

export default {
  name: 'BolusRecurringDose',
  components: { DoseInput, DoseCardBase },
  mixins: [addPrescriptionMixin],
  data() {
    return {
      formValid: false,
      prescription: this.createInitialPrescription(),
    }
  },
  computed: {
    bolusCountError() {
      if (this.numberOfActiveBolus < 2)
        return 'At least 2 bolus doses need to be active in order to proceed.'

      return null
    },
    numberOfActiveBolus() {
      return (
        Number(this.prescription.bolus_breakfast.enabled) +
        Number(this.prescription.bolus_lunch.enabled) +
        Number(this.prescription.bolus_dinner.enabled) +
        Number(this.prescription.bolus_extra_prescription.enabled)
      )
    },
  },
  methods: {
    createInitialPrescription() {
      const doseConfiguration = this.sessionResponse.doses
      const result = {}
      for (const config of doseConfiguration) {
        result[config.treatment.timepoint] = {
          value: null,
          enabled: config.isActive,
        }
      }
      return result
    },
    getDoses() {
      let doses = []
      const doseConfiguration = this.sessionResponse.doses
      for (const config of doseConfiguration) {
        const dose = this.prescription[config.treatment.timepoint]

        if (dose.enabled && isNumeric(dose.value)) {
          doses.push({
            timepoint: config.treatment.timepoint,
            doseValue: parseFloat(dose.value),
            isActive: true,
            auditEntries: this.auditLog.getAuditEntries(),
          })
        }
      }
      return doses
    },
    getDosingDisplayUnit(timepoint) {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === timepoint
      ).treatment.dosingDisplayUnit
    },
    getValidationRules(timepoint) {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === timepoint
      ).treatment.allowedDoseRange
    },
    isDirty() {
      return this.sessionResponse.doses.some(
        x => this.prescription[x.treatment.timepoint].value
      )
    },
    resetForm() {
      this.$refs.bolusPrescriptionForm.reset()
    },
    onChange(value, name) {
      this.auditLog.log(name, value)
    },
    onInput(value, timepoint) {
      this.prescription[timepoint].value = value
    },
    onToggle(value, timepoint) {
      this.prescription[timepoint].enabled = value

      this.auditLog.log(
        `PRESCRIBE_DOSE:${timepoint.toUpperCase()}_NOT_APPLICABLE`,
        !value
      )
    },
  },
}
</script>
