var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DoseCardBase',{ref:"baseCard",attrs:{"session-response":_vm.sessionResponse},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Bolus dose prescription")]},proxy:true},{key:"tabOneText",fn:function(){return [_vm._v("Dose prescription")]},proxy:true}])},[_c('v-form',{ref:"bolusPrescriptionForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-8 pb-5 pl-6",attrs:{"md":"10"}},[_c('h6',[_vm._v("Prescribe bolus doses")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-8 pl-6",attrs:{"md":"10"}},[_c('v-row',[_c('v-col',{staticClass:"px-2",attrs:{"sm":"3"}},[_c('DoseInput',{attrs:{"value":_vm.prescription.bolus_breakfast.value,"validation-rules":_vm.getValidationRules('bolus_breakfast'),"data-testid":"input-bolus-breakfast","is-togglable":true,"deactivated":!_vm.prescription.bolus_breakfast.enabled,"dosing-display-unit":_vm.getDosingDisplayUnit('bolus_breakfast'),"required-text":`${_vm.$t(
                  'time-point.bolus_breakfast'
                )} is required`},on:{"input":function($event){return _vm.onInput($event, 'bolus_breakfast')},"change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_VALUE')},"toggled-active":function($event){return _vm.onToggle($event, 'bolus_breakfast')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point.bolus_breakfast'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"3"}},[_c('DoseInput',{attrs:{"value":_vm.prescription.bolus_lunch.value,"validation-rules":_vm.getValidationRules('bolus_lunch'),"data-testid":"input-bolus-lunch","is-togglable":true,"deactivated":!_vm.prescription.bolus_lunch.enabled,"dosing-display-unit":_vm.getDosingDisplayUnit('bolus_lunch'),"required-text":`${_vm.$t('time-point.bolus_lunch')} is required`},on:{"input":function($event){return _vm.onInput($event, 'bolus_lunch')},"change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_VALUE')},"toggled-active":function($event){return _vm.onToggle($event, 'bolus_lunch')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point.bolus_lunch'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"3"}},[_c('DoseInput',{attrs:{"value":_vm.prescription.bolus_dinner.value,"validation-rules":_vm.getValidationRules('bolus_dinner'),"data-testid":"input-bolus-dinner","is-togglable":true,"deactivated":!_vm.prescription.bolus_dinner.enabled,"dosing-display-unit":_vm.getDosingDisplayUnit('bolus_dinner'),"required-text":`${_vm.$t(
                  'time-point.bolus_dinner'
                )} is required`},on:{"input":function($event){return _vm.onInput($event, 'bolus_dinner')},"change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_VALUE')},"toggled-active":function($event){return _vm.onToggle($event, 'bolus_dinner')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point.bolus_dinner'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"3"}},[_c('DoseInput',{attrs:{"value":_vm.prescription.bolus_extra_prescription.value,"validation-rules":_vm.getValidationRules('bolus_extra_prescription'),"data-testid":"input-bolus-extra","is-togglable":true,"deactivated":!_vm.prescription.bolus_extra_prescription.enabled,"dosing-display-unit":_vm.getDosingDisplayUnit('bolus_extra_prescription'),"required-text":`${_vm.$t(
                  'time-point.bolus_extra_prescription'
                )} is required`},on:{"input":function($event){return _vm.onInput($event, 'bolus_extra_prescription')},"change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_EXTRA_VALUE')},"toggled-active":function($event){return _vm.onToggle($event, 'bolus_extra_prescription')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point.bolus_extra_prescription'))+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-end mr-6"},[_c('v-input',{attrs:{"error-messages":_vm.bolusCountError,"hide-details":""}}),(_vm.bolusCountError)?_c('div',{staticClass:"error--text mr-6 font-size-14"},[_vm._v(" "+_vm._s(_vm.bolusCountError)+" ")]):_vm._e(),_c('div',[_c('v-btn',{attrs:{"data-testid":"add-confirm-button","rounded":"","color":"secondary","disabled":!_vm.formValid,"loading":_vm.isLoading,"type":"submit","elevation":"1"},on:{"click":function($event){$event.preventDefault();_vm.createPrescription(_vm.getDoses())}}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }