import Vue from 'vue'
import timepointKeys from '@/constants/timepointKeys'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import loadingState from '@/constants/loadingState'
import prescriptionService from '@/services/prescription-service'
import AuditLog from '@/utils/audit/AuditLog'

export default {
  props: {
    sessionResponse: { type: Object, required: true },
  },
  data() {
    return {
      auditLog: new AuditLog(),
      state: loadingState.INITIAL,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    doseValueAuditKey(timepoint) {
      switch (timepoint) {
        case timepointKeys.basal_once_daily:
          return 'PRESCRIBE_DOSE:DAILY_DOSE_VALUE'
        case timepointKeys.basal_once_weekly:
          return 'PRESCRIBE_DOSE:WEEKLY_DOSE_VALUE'
        case timepointKeys.bolus_breakfast:
          return 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_VALUE'
        case timepointKeys.bolus_lunch:
          return 'PRESCRIBE_DOSE:BOLUS_LUNCH_VALUE'
        case timepointKeys.bolus_dinner:
          return 'PRESCRIBE_DOSE:BOLUS_LUNCH_VALUE'
        case timepointKeys.bolus_extra_prescription:
          return 'PRESCRIBE_DOSE:BOLUS_EXTRA_VALUE'
        default:
          return 'PRESCRIBE_DOSE:DOSE_VALUE'
      }
    },
    refreshData() {
      this.$refs.baseCard.refreshData()
    },
    createPrescription(doses, bloodGlucoseUnit?) {
      this.state = loadingState.LOADING

      prescriptionService
        .setPrescription(
          this.sessionResponse.patientNumber,
          this.sessionResponse.sessionId,
          dateTimeWithTimeZone(),
          doses,
          bloodGlucoseUnit
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('done', true)
        })
        .catch(error => {
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
          this.state = loadingState.LOAD_ERRORED

          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Data could not be sent, please try again.',
          })
        })
    },
  },
}
