<template>
  <v-card
    class="elevation-0 rounded-bottom-lg"
    data-testid="prescription-add-dose-card"
  >
    <v-card-text class="px-0 py-0 prescription-timepoint">
      <h4 class="mt-1 d-inline-block">
        <slot name="title" />
      </h4>

      <v-tabs
        class="mt-4"
        v-model="tab"
        background-color="#f7f9fc"
        active-class="active-tab"
      >
        <v-tabs-slider color="#005ad2" style="width: 46%; margin-left: 27%" />
        <v-tab><slot name="tabOneText">Titration data</slot></v-tab>
        <v-tab :class="{ 'is-hypo': hasHypoValueWithinLast7days }">
          <IconArrowDown
            v-if="hasHypoValueWithinLast7days"
            class="is-hypo icon-arrow-down mr-2"
          />
          BG values in the last 7 days
        </v-tab>
        <v-tab> Doses in last 7 days </v-tab>
        <v-tab> Doses in last 28 days </v-tab>
      </v-tabs>
    </v-card-text>

    <v-tabs-items v-model="tab" class="rounded-bottom-lg elevation-1 pb-4">
      <v-tab-item>
        <slot />
      </v-tab-item>
      <v-tab-item class="pt-4 px-8">
        <h4 class="mb-4">Blood glucose values in the last 7 days</h4>
        <SmbgList
          :patient-no="sessionResponse.patientNumber"
          :max-days="6"
          :hide-audit="true"
          :noControlSolutions="true"
          ref="smbgList"
      /></v-tab-item>
      <v-tab-item class="pt-4 px-8">
        <h4 class="mb-4">Doses in the last 7 days</h4>
        <InsulinList
          :patient-no="sessionResponse.patientNumber"
          :max-days="6"
          :hide-audit="true"
          ref="insulinList"
        />
      </v-tab-item>
      <v-tab-item class="pt-4 px-8">
        <h4 class="mb-4">Doses in the last 28 days</h4>
        <InsulinList
          :patient-no="sessionResponse.patientNumber"
          :max-days="27"
          :hide-audit="true"
          ref="insulinList"
        />
      </v-tab-item>
    </v-tabs-items>
    <div ref="bottomscreen" class="bottom-screen"></div>
  </v-card>
</template>

<script>
import IconArrowDown from '@/components/icons/IconArrowDown'

export default {
  name: 'DoseCardBase',
  components: {
    IconArrowDown,
    InsulinList: () => import('@/components/insulin/InsulinList'),
    SmbgList: () =>
      import(
        '@/components/blood-glucose-measurements/self-monitoring/SmbgList'
      ),
  },
  props: {
    sessionResponse: { type: Object, required: true },
  },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    hasHypoValueWithinLast7days() {
      return this.sessionResponse.haveHadHypoWithinLastSevenDays
    },
  },
  methods: {
    refreshData() {
      this.$refs.smbgList?.refreshData()
      this.$refs.insulinList?.refreshData()
    },
    scrollToBottom() {
      this.$refs.bottomscreen?.scrollIntoView?.({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-box-shadow: 0 3px 2px rgba(56, 61, 69, 0.08),
    0 0 2px rgba(56, 61, 69, 0.04) !important;
  box-shadow: 0 3px 2px rgba(56, 61, 69, 0.08), 0 0 2px rgba(56, 61, 69, 0.04) !important;
}
.prescription-timepoint {
  background-color: $nn-body-background;

  .v-tab {
    text-transform: none !important;
    color: $nn-sea-blue !important;
    font-weight: bold;

    &.is-hypo {
      color: $nn-lava-red !important;
    }
  }
}
.icon-arrow-down {
  margin-left: 4px;
  width: 14px;
  height: 16px;
  position: relative;
  top: -1px;
}
</style>
